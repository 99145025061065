#menu-superior-total {
  color: #c5c5c5; }
  #menu-superior-total #botao-filtros-navtop, #menu-superior-total #botao-menu-painel, #menu-superior-total #botao-menu-admin {
    color: white;
    padding-left: 20px;
    cursor: pointer; }
    #menu-superior-total #botao-filtros-navtop:hover, #menu-superior-total #botao-menu-painel:hover, #menu-superior-total #botao-menu-admin:hover {
      color: #ff6722; }
  #menu-superior-total #botao-painel-filtros {
    display: none; }
  @media (min-width: 992px) {
    #menu-superior-total #botao-painel-filtros {
      display: inline-block;
      margin-left: 280px; }
    #menu-superior-total #botao-filtros-navtop, #menu-superior-total #botao-menu-painel, #menu-superior-total #botao-menu-admin {
      display: none; } }
  #menu-superior-total #sino-notificacoes, #menu-superior-total #sino-colapsado {
    position: relative;
    margin-top: 12px;
    font-size: 18px;
    cursor: pointer;
    color: white; }
    #menu-superior-total #sino-notificacoes:hover, #menu-superior-total #sino-colapsado:hover {
      background-color: transparent;
      color: #ff6722; }
  #menu-superior-total #sino-notificacoes {
    margin-right: 10px; }
  #menu-superior-total #sino-colapsado {
    margin-right: 30px;
    border: none;
    padding: 0; }
  #menu-superior-total .badge-notificacoes {
    position: absolute;
    top: -5px;
    left: 16px;
    font-size: 13px;
    font-weight: bold;
    color: #ff6722; }
  #menu-superior-total button.navbar-toggle, #menu-superior-total button.navbar-toggle.collapsed {
    margin-right: 30px; }
  #menu-superior-total #menu-usuario {
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0 15px 0 0; }
    #menu-superior-total #menu-usuario img {
      height: 46px;
      border-radius: 50%; }
      #menu-superior-total #menu-usuario img:hover {
        border: 2px solid #ff6722; }
  #menu-superior-total #menu-superior ul.nav.navbar-nav > li {
    text-align: center; }
  #menu-superior-total #menu-superior a.link-navbar {
    color: white;
    font-weight: bold;
    outline: none;
    transition: all .3s; }
    #menu-superior-total #menu-superior a.link-navbar:hover, #menu-superior-total #menu-superior a.link-navbar:active, #menu-superior-total #menu-superior a.link-navbar:focus, #menu-superior-total #menu-superior a.link-navbar.ativo {
      color: #ff6722;
      box-shadow: inset 0 -4px 0 #ff6722; }
  #menu-superior-total #menu-superior #dropdown-menu-usuario {
    background-color: #0B486B; }
    #menu-superior-total #menu-superior #dropdown-menu-usuario a {
      color: white;
      font-size: 1.1em; }
      #menu-superior-total #menu-superior #dropdown-menu-usuario a:hover {
        color: #ff6722;
        background-color: #0b496c;
        box-shadow: inset 4px 0 #ff6722; }
  #menu-superior-total #menu-superior a#menu-usuario.link-navbar, #menu-superior-total #menu-superior.ativo {
    box-shadow: none; }
    #menu-superior-total #menu-superior a#menu-usuario.link-navbar:hover, #menu-superior-total #menu-superior.ativo:hover {
      box-shadow: none; }
    #menu-superior-total #menu-superior a#menu-usuario.link-navbar.ativo > img, #menu-superior-total #menu-superior.ativo.ativo > img {
      border: 2px solid #ff6722; }

.nav-cor-padrao {
  background-color: #0B486B; }
  .nav-cor-padrao #menu-usuario {
    background-color: #0B486B; }

/*.nav-cor-transparente {
  background-color: transparentize($fundo-azul, .7);
  background-color: #032944;
}*/
#corpo-modal-notificacoes .exibindo {
  font-size: 1.1em; }

#corpo-modal-notificacoes .notificacao {
  margin: 10px 0;
  padding: 15px 10px;
  font-size: 1em;
  color: white;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.06); }
  #corpo-modal-notificacoes .notificacao a {
    color: white; }
  #corpo-modal-notificacoes .notificacao .rodape {
    margin-top: 5px;
    font-size: 0.9em;
    font-style: italic;
    color: gray; }
    #corpo-modal-notificacoes .notificacao .rodape .marcar {
      cursor: pointer;
      float: right;
      font-style: normal; }
      #corpo-modal-notificacoes .notificacao .rodape .marcar:hover {
        color: #ff6722; }

#corpo-modal-notificacoes .notificacao.lida {
  color: gray;
  border: 1px solid gray;
  background-color: transparent; }

#painel-notificacoes {
  display: none;
  width: 320px;
  height: 200px;
  position: absolute;
  top: 55px;
  right: 22px;
  background-color: #0B486B; }
  #painel-notificacoes .cabecalho, #painel-notificacoes .rodape {
    display: block;
    background-color: #ff6722;
    color: white;
    text-align: center;
    font-size: 0.9em;
    padding: 2px; }
  #painel-notificacoes .rodape {
    position: absolute;
    bottom: -5px;
    padding: 0;
    width: 100%;
    font-size: 0.8em; }
    #painel-notificacoes .rodape a {
      color: white; }
  #painel-notificacoes .corpo {
    padding: 0 5px;
    font-size: 13px;
    color: white; }
    #painel-notificacoes .corpo .sem-notificacoes {
      font-style: italic;
      padding-top: 70px;
      text-align: center; }
    #painel-notificacoes .corpo .redirecionando {
      color: white;
      font-size: 30px;
      text-align: center;
      padding-top: 60px; }
    #painel-notificacoes .corpo .notificacao {
      height: 42px;
      width: 100%;
      position: relative;
      padding: 2px 30px 2px 0;
      border-bottom: 1px solid gray; }
      #painel-notificacoes .corpo .notificacao a {
        color: white;
        text-decoration: none; }
        #painel-notificacoes .corpo .notificacao a:hover {
          color: #ff6722; }
      #painel-notificacoes .corpo .notificacao i.icone-visto {
        position: absolute;
        right: 7px;
        top: 30%;
        font-size: 12px;
        cursor: pointer; }
        #painel-notificacoes .corpo .notificacao i.icone-visto:hover {
          color: #ff6722; }
    #painel-notificacoes .corpo .notificacao.lida {
      color: grey; }

#painel-notificacoes:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  color: transparent;
  border: 10px solid black;
  border-color: transparent transparent #ff6722;
  margin-top: -20px;
  margin-left: 222px; }

/* Estilos para o modal de Login e Registrar */
.login-or {
  position: relative;
  margin: 10px 20px;
  font-size: 16px;
  color: #a6b2b9; }

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: 10px;
  margin-left: -100px;
  background-color: #F6F6F6;
  width: 200px;
  text-align: center; }

.user-modal-container * {
  box-sizing: border-box; }

.user-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  overflow-y: auto;
  z-index: 9999;
  /*font-family:'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';*/
  font-size: 14px;
  background-color: rgba(17, 17, 17, 0.9);
  transition: all 0.25s linear; }
  .user-modal-container input.erro {
    border-color: red; }
  .user-modal-container #titulo-redefinir-senha {
    margin: 0;
    padding: 15px;
    text-align: center;
    color: gray; }

.user-modal-container.active {
  opacity: 1;
  visibility: visible; }

.user-modal-container .user-modal {
  position: relative;
  margin: 80px auto;
  width: 90%;
  max-width: 500px;
  background-color: #f6f6f6;
  cursor: initial; }

.user-modal-container .form-login, .user-modal-container .form-register, .user-modal-container .form-password {
  padding: 0 25px 25px;
  display: none; }

.user-modal-container .form-login.active, .user-modal-container .form-register.active, .user-modal-container .form-password.active {
  display: block; }

.user-modal-container ul.form-switcher {
  margin: 0;
  padding: 0; }

.user-modal-container ul.form-switcher li {
  list-style: none;
  display: inline-block;
  width: 50%;
  float: left;
  margin: 0; }

.user-modal-container ul.form-switcher li a {
  width: 100%;
  display: block;
  height: 50px;
  line-height: 50px;
  color: #666666;
  background-color: #dddddd;
  text-align: center; }

.user-modal-container ul.form-switcher li a.active {
  color: #878787;
  background-color: #f6f6f6;
  outline: 0;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.15em; }

.user-modal-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eeeeee; }

.user-modal-container .links {
  text-align: center;
  padding-top: 25px; }

.user-modal-container .links a {
  color: #3fb67b; }

/*# sourceMappingURL=menu-superior.css.map */
